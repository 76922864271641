<template>
	<div class="relative cursor-pointer py-2 mr-1 last:mr-0 justify-self-end ml-auto">
		<router-link class="hover:bg-white hover:text-gray-600 transition-all p-3 rounded-md" :to="{ name: 'search' }">
      <font-awesome-icon class="mr-1" icon="fa-solid fa-magnifying-glass" /> Search
    </router-link>
	</div>
</template>

<script>
export default {
	inject: ['openSearchBox']
}
</script>
