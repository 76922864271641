export default {
    guiOptions: {
        consentModal: {
            layout: "cloud",
            position: "bottom center",
        },
        preferencesModal: {
            layout: "box",
        },
    },
    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {},
    },
    language: {
        default: "hu",
        translations: {
            hu: {
                consentModal: {
                    description:
                        'Ez a weboldal cookie-kat (sütiket) használ a szolgáltatásaink és az Ön élményének javítása érdekében a biztonságos böngészés biztosítása mellett. Ha az alábbiakban nem fogadja el az opcionális cookie-kat, az hatással lehet az Ön weboldal látogatási élményére. <br> További információkért - ideértve a cookie-k használatát, illetve a cookie-k beállítását - kérjük, olvassa el a <a href="/cookie-policy" class="cc__link">Cookie Policy.</a>',
                    acceptAllBtn: "Minden süti elfogadása",
                    acceptNecessaryBtn:
                        "Kizárólag a szükséges sütik alkalmazása",
                    showPreferencesBtn: "Testreszabás",
                    closeIconLabel: "",
                },
                preferencesModal: {
                    title: "Kérjük válasszon az alábbi sütik közül:",
                    acceptAllBtn: "Minden süti elfogadása",
                    acceptNecessaryBtn:
                        "Kizárólag a szükséges sütik alkalmazása",
                    savePreferencesBtn: "Beállítások mentése",
                    sections: [
                        {
                            description:
                                'A megadott hozzájárulását bármikor visszavonhatja a weboldal alján található Süti tájékoztató menüpont alatt. A hozzájárulásának visszavonása nem érinti az azt megelőző hozzájárulás birtokában végzett adatkezelés jogszerűségét. <br> További információkért - ideértve a sütik használatát, illetve a sütik beállítását - kérjük, olvassa el a <a href="/cookie-policy" class="cc__link">Cookie Policy.</a>',
                        },
                        {
                            title: "Feltétlenül szükséges sütik",
                            description:
                                "Ezek a sütik feltétlenül szükségesek Honlapunk megfelelő működéséhez.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                headers: {
                                    name: "Név",
                                    domain: "Szolgáltató",
                                    expire: "Lejárat",
                                    type: "Típus",
                                    desc: "Leírás",
                                    collect: "Milyen adatot gyűjt",
                                    noneu: "Harmadik országba történő adattovábbítás",
                                },
                                body: [
                                    {
                                        name: "cardiologia_hungarica_session",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "1 nap",
                                        type: "HTTP Süti",
                                        desc: "Felhasználói munkamenet azonosítására létrehozott süti. Az oldal nem működik megfelelően nélküle.",
                                        collect:
                                            "Ez a süti nem gyűjti vagy tárolja a honlap látogatóinak semmilyen személyes adatát.",
                                        noneu: "nem",
                                    },
                                    {
                                        name: "XSRF-Token",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "1 nap",
                                        type: "HTTP Süti",
                                        desc: "Az alkalmazás minden aktív felhasználó számára nyilvántartásba vesz egy egyedi azonosítót (CSRF 'token'), amellyel ellenőrizni lehet, hogy valóban a hitelesített felhasználó az, aki a kérést küldte az alkalmazás részére.",
                                        collect:
                                            "Ez a süti nem gyűjti vagy tárolja a honlap látogatóinak semmilyen személyes adatát.",
                                        noneu: "nem",
                                    },
                                    {
                                        name: "cc_cookie",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "180 nap",
                                        type: "HTTP Süti",
                                        desc: "Ezt a sütit a cookieconsent használja, hogy eltárolja a felhasználók süti beállításait, és azokat használja az oldal ismételt felkeresésekor. Ez a süti nem gyűjti vagy tárolja a honlap látogatóinak semmilyen személyes adatát.",
                                        collect:
                                            "Ez a süti nem gyűjti vagy tárolja a honlap látogatóinak semmilyen személyes adatát.",
                                        noneu: "nem",
                                    },
                                    {
                                        name: "_GRECAPTCHA",
                                        domain: "google.com",
                                        expire: "180 nap",
                                        type: "HTTP Süti",
                                        desc: "Ezt a sütit a Google reCAPTCHA állítja be, amely megvédi a weboldalt a kapcsolatfelvételi űrlapon megjelenő támadásokkal szemben.",
                                        collect:
                                            "Hardver és szoftver információkat, például eszköz és alkalmazás adatokat gyűjt a szolgáltatás használatával kapcsolatban, amelyeket a reCAPTCHA fejlesztésére és általános biztonsági célokra használnak fel. A Google a gyűjtött információkat nem használja fel személyre szabott hirdetésekre.",
                                        noneu: "igen",
                                    },
                                ],
                            },
                        },
                        {
                            title: "Analitikai sütik",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Név",
                                    domain: "Szolgáltató",
                                    expire: "Lejárat",
                                    type: "Típus",
                                    desc: "Leírás",
                                    collect: "Milyen adatot gyűjt",
                                    noneu: "Harmadik országba történő adattovábbítás",
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "2 év",
                                        type: "HTTP Süti",
                                        desc: "Forgalmi adatok rögzítésére létrehozott süti. Ezeket a sütiket arra használjuk, hogy információkat gyűjtsünk arról, hogy a látogatók hogyan használják weboldalt. Az információkat jelentések összeállítására és a weboldal fejlesztésére használjuk.",
                                        collect:
                                            "Webhelylátogatási és felhasználói viselkedés adatokat gyűjt.",
                                        noneu: "igen",
                                    },
                                    {
                                        name: "_ga_(container-id)",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "2 év",
                                        type: "HTTP Süti",
                                        desc: "A munkamenet állapotának megőrzésére szolgál.",
                                        collect:
                                            "Webhelylátogatási és felhasználói viselkedés adatokat gyűjt.",
                                        noneu: "igen",
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        },
    },
}
